export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    pageTitle: 'Статистика',
    meta: {
      pageTitle: 'Сводная статистика',
      breadcrumb: [
        {
          text: 'Статистика',
        },
        {
          text: 'Сводная таблица',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/audience-report',
    name: 'audience-report',
    component: () => import('@/views/dashboard/analytics/AudienceReport.vue'),
    pageTitle: 'Отчет по аудитории',
    meta: {
      pageTitle: 'Отчет по аудитории',
      breadcrumb: [
        {
          text: 'Статистика',
        },
        {
          text: 'Отчет по аудитории',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/summary-stat',
    name: 'summary-stat',
    component: () => import('@/views/dashboard/analytics/SummaryStat.vue'),
    pageTitle: 'Сводная таблица',
    meta: {
      pageTitle: 'Сводная таблица',
      breadcrumb: [
        {
          text: 'Статистика',
        },
        {
          text: 'Сводная таблица',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/summary-stat-custom',
    name: 'summary-stat-custom',
    component: () => import('@/views/dashboard/analytics/SummaryStatCustom.vue'),
    pageTitle: 'Сводная таблица по дням',
    meta: {
      pageTitle: 'Сводная таблица по дням',
      breadcrumb: [
        {
          text: 'Статистика',
        },
        {
          text: 'Сводная таблица по дням',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/summary-stat-compare',
    name: 'summary-stat-compare',
    component: () => import('@/views/dashboard/analytics/SummaryStatCompare.vue'),
    pageTitle: 'Сравнение',
    meta: {
      pageTitle: 'Сравнение',
      breadcrumb: [
        {
          text: 'Статистика',
        },
        {
          text: 'Сравнение',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/rules',
    name: 'rules',
    component: () => import('@/views/dashboard/rules/RuleTable.vue'),
    pageTitle: 'Таблица правил',
    meta: {
      pageTitle: 'Правила',
      breadcrumb: [
        {
          text: 'Правила',
        },
        {
          text: 'Таблица правил',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/favorite-comps',
    name: 'favorite-comps',
    component: () => import('@/views/dashboard/fav-comps/FavCompsTable.vue'),
    pageTitle: 'Список избранных кампаний',
    meta: {
      pageTitle: 'Избранные кампании',
      breadcrumb: [
        {
          text: 'Избранные кампании',
        },
        {
          text: 'Таблица избранных кампаний',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/addFavComp/:favCompId',
    name: 'add-fav-comp',
    props: route => ({ ruleId: route.params.favCompId }),
    component: () => import('@/views/dashboard/fav-comps/AddFavComp.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Таблица избранных кампаний',
          to: '/dashboard/fav-comps',
        },
        {
          text: 'Добавление спсика избранных кампаний',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/addRule/:ruleId',
    name: 'add-rule',
    props: route => ({ ruleId: route.params.ruleId }),
    component: () => import('@/views/dashboard/rules/AddRule.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Таблица правил',
          to: '/dashboard/rules'
        },
        {
          text: 'Добавление правила',
          active: true,
        },
      ],
    },
  },
  {
    name: 'rule-detail',
    path: '/dashboard/ruleDetail/:ruleId',
    props: route => ({ ruleId: route.params.ruleId }),
    component: () => import('@/views/dashboard/rules/RuleDetails.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Таблица правил',
          to: '/dashboard/rules',
        },
        {
          text: 'Просмотр правила',
          active: true,
        },
      ],
    },
  },
]
