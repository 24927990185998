/*
  Project store
 */

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    activeProjectId: null,
    isChanged: false,
    user: null,
    projects: [],
  },
  getters: {},
  mutations: {
    CLEAR_USER(state) {
      state.user = null
    },
    UPDATE_USER(state, user) {
      state.user = user
    },
    CLEAR_ACTIVE_PROJECT_ID(state) {
      state.activeProjectId = null
      localStorage.removeItem('active_project_id')
    },
    UPDATE_ACTIVE_PROJECT_ID(state, id) {
      state.activeProjectId = id
      localStorage.setItem('active_project_id', id)
    },
    UPDATE_LOADING_PROJECT(state, val) {
      state.isChanged = val
    },
    UPDATE_PROJECT_BY_ID(state, val) {
      state.projects = state.projects.filter(c => c.id !== val.id)
      state.projects.push(val)
    },
    UPDATE_PROJECTS_LIST(state, val) {
      state.projects = val
      const activeProjectIdStorage = localStorage.getItem('active_project_id')
      if (activeProjectIdStorage != null) {
        state.activeProjectId = activeProjectIdStorage
      }
    },
    ADD_PROJECT_IN_LIST(state, val) {
      state.projects.push(val)
    },
  },
  actions: {
    fetchAllUserProjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/project/shorts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectById(ctx, { projectId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/project/${projectId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProjectField(ctx, project) {
      return new Promise((resolve, reject) => {
        axios
          .post('/project/edit', project)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadAvatar(ctx, { formData, projectId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/project/avatar/${projectId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
