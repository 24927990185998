// eslint-disable-next-line import/no-unresolved
import { HubConnectionBuilder } from '@microsoft/signalr'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {
    const params = {
      prepareData: null,
    }
    const serverHub = new Vue({
      data: params,
    })

    Vue.prototype.$serverHub = serverHub
    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    Vue.prototype.startSignalR = () => {
      const token = localStorage.getItem('access_token')
      connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_BASE_URL}/hub`,
          { accessTokenFactory: () => token })
        .build()

      function start() {
        startedPromise = connection.start()
          .catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start()
              .then(resolve)
              .catch(reject), 5000))
          })
        return startedPromise
      }

      connection.on('PrepareData', (text, projectId, isEnded) => {
        serverHub.$emit('prepare-data', { text, projectId, isEnded })
        // eslint-disable-next-line no-underscore-dangle
      })

      connection.on('ConfirmEmail', text => {
        serverHub.$emit('confirm', { text })
        // eslint-disable-next-line no-underscore-dangle
      })

      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      start()
    }

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null
        })
    }
  },
}
