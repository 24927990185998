import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    // delivery
    delivery: {
      icon: 'ShoppingBagIcon',
      title: 'Проекты',
      subtitle: 'Вся информация о проектах',
      qandA: [
        {
          question: 'Как создать новый проект?',
          ans:
            'Перейдите в раздел создания проектов и заполните форму.',
        },
        {
          question: 'Как удалить проект?',
          ans:
            'Перейдите в проект, в раздел общей информации и удалите проект',
        },
      ],
    },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
