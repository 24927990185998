export default [
  {
    path: '/connections/:connectionId',
    name: 'new-connection',
    props: route => ({
      connectionId: route.params.connectionId,
      projectId: route.params.projectId,
    }),
    component: () => import('@/views/connection/NewConnection.vue'),
    meta: {},
  },
  {
    path: '/apply-yandex-direct-code',
    name: 'apply-yandex-direct-code',
    component: () => import('@/views/connection/Integrations/YandexDirect/ApplyYandexDirectCode.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Проекты',
          to: '/projects',
        },
        {
          text: 'Новое подключение',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apply-yandex-metrika-code',
    name: 'apply-yandex-metrika-code',
    component: () => import('@/views/connection/Integrations/YandexMetrika/ApplyMetrikaCode.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Проекты',
          to: '/projects',
        },
        {
          text: 'Новое подключение',
          active: true,
        },
      ],
    },
  },
  {
    path: '/yandex-metrika/:connectionId',
    name: 'yandex-metrika',
    props: route => ({ connectionId: route.params.connectionId, metrikaTokens: route.params.metrikaTokens }),
    component: () => import('@/views/connection/Integrations/YandexMetrika/AddYandexMetrika.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Подключения',
          to: '/projects',
        },
        {
          text: 'Yandex Metrika',
          active: true,
        },
      ],
    },
  },
  {
    path: '/google-analytics/:connectionId',
    name: 'google-analytics',
    props: route => ({ connectionId: route.params.connectionId }),
    component: () => import('@/views/connection/Integrations/GoogleAnalytics/AddGoogleAnalytics.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Подключения',
          to: '/projects',
        },
        {
          text: 'Google Analytics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/google-adwords/:connectionId',
    name: 'google-adwords',
    props: route => ({ connectionId: route.params.connectionId }),
    component: () => import('@/views/connection/Integrations/GoogleAdwords/AddGoogleAdwords.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Подключения',
          to: '/projects',
        },
        {
          text: 'Google Adwords',
          active: true,
        },
      ],
    },
  },
  {
    path: '/yandex-direct/:connectionId',
    name: 'yandex-direct',
    props: route => ({ connectionId: route.params.connectionId, directTokens: route.params.directTokens }),
    component: () => import('@/views/connection/Integrations/YandexDirect/AddYandexDirect.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Подключения',
          to: '/projects',
        },
        {
          text: 'Yandex Direct',
          active: true,
        },
      ],
    },
  },
]
