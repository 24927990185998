export default [
  {
    path: '/plan-fact',
    name: 'plan-fact',
    component: () => import('@/views/planfact/PlanFact.vue'),
    meta: {
      pageTitle: 'План-факт',
      breadcrumb: [
        {
          text: 'План-факт',
          active: true,
        },
      ],
    },
  },
]
