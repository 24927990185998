export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/Projects.vue'),
    pageTitle: 'Список проектов',
    meta: {
      pageTitle: 'Проекты',
      breadcrumb: [
        {
          text: 'Список проектов',
        },
        {
          text: 'Проекты',
          active: true,
        },
      ],
    },
  },
  {
    path: '/projects/new',
    name: 'new-project',
    component: () => import('@/views/projects/AddProject.vue'),
    pageTitle: 'Новый проект',
    meta: {
      breadcrumb: [
        {
          text: 'Список проектов',
          to: '/projects'
        },
        {
          text: 'Новый проект',
          active: true,
        },
      ],
    },
  },
  {
    path: '/projects/:projectId',
    name: 'projects-detail',
    props: route => ({ projectId: route.params.projectId, hash: route.params.hash }),
    component: () => import('@/views/projects/ProjectDetail.vue'),
    meta: {
      pageTitle: 'Информация о проекте',
      breadcrumb: [
        {
          text: 'Список проектов',
          to: '/projects',
        },
        {
          text: 'Информация о проекте',
          active: true,
        },
      ],
    },
  },
]
