export default [
  {
    path: '/error-links',
    name: 'error-links',
    component: () => import('@/views/error-links/ErrorLinks.vue'),
    meta: {
      pageTitle: 'Ссылки',
      breadcrumb: [
        {
          text: 'Проверка ссылок',
        },
        {
          text: 'Проверка на 404',
          active: true,
        },
      ],
    },
  },
]
