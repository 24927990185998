/*
  Statistic store
 */

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tableData: null,
    summaryTableData: null,
    summaryTableCustomData: null,
    summaryTableCompareData: null,
    graphData: null,
    recommendationStats: null,
    efficiencyStats: null,
    kpiData: null,
    settingResult: null,
  },
  getters: {},
  mutations: {
    LOAD_SETTING_REC(state, data) {
      state.settingResult = data
    },
    CLEAR_SETTING_REC(state) {
      state.settingResult = null
    },
    LOAD_KPI(state, data) {
      state.kpiData = data
    },
    CLEAR_KPI(state) {
      state.kpiData = null
    },
    LOAD_RECOMMENDATION_DATA(state, data) {
      state.recommendationStats = data
    },
    LOAD_EFFICIENCY_DATA(state, data) {
      state.efficiencyStats = data
    },
    CLEAR_RECOMMENDATION_DATA(state) {
      state.recommendationStats = null
    },
    CLEAR_EFFICIENCY_DATA(state) {
      state.efficiencyStats = null
    },
    LOAD_TABLE_DATA(state, data) {
      state.tableData = data
    },
    LOAD_SUMMARY_TABLE_DATA(state, data){
      state.summaryTableData = data
    },
    LOAD_SUMMARY_TABLE_CUSTOM_DATA(state, data){
      state.summaryTableCustomData = data
    },
    LOAD_SUMMARY_TABLE_COMPARE_DATA(state, data){
      state.summaryTableCompareData = data
    },
    LOAD_GRAPH_DATA(state, data) {
      state.graphData = data
    },
    CLEAR_TABLE_DATA(state) {
      state.tableData = null
    },
    CLEAR_SUMMARY_TABLE_DATA(state) {
      state.summaryTableData = null
    },
    CLEAR_SUMMARY_TABLE_CUSTOM_DATA(state) {
      state.summaryTableCustomDataData = null
    },
    CLEAR_GRAPH_DATA(state) {
      state.graphData = null
    },
  },
  actions: {
    fetchTableStatistic(ctx, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/statistic/table', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGraphStatistic(ctx, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/statistic/graph', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSummaryTableStatistic(ctx, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/statistic/summary-stats', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSummaryTableStatisticCustom(ctx, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/statistic/summary-stats', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSummaryTableCompare(ctx, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/statistic/summary-stats-compare', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
