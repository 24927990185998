export default [
  {
    path: '/backend-logs',
    name: 'logs',
    component: () => import('@/views/logs/Logs.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Подключения',
          to: '/projects',
        },
        {
          text: 'Logs',
          active: true,
        },
      ],
    },
  },
]
