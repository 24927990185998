export default [
  {
    path: '/recommendations/settings',
    name: 'recommendations-settings',
    component: () => import('@/views/recommendations/RecommendationsSettings.vue'),
    pageTitle: 'Настройка рекомендаций',
    meta: {
      pageTitle: 'Настройка рекомендаций',
      breadcrumb: [
        {
          text: 'Настройка рекомендаций',
          active: true,
        },
      ],
    },
  },
  {
    path: '/recommendations/table',
    name: 'recommendations-table',
    component: () => import('@/views/recommendations/RecommendationsTable.vue'),
    pageTitle: 'Рекомендации',
    meta: {
      pageTitle: 'Рекомендации',
      breadcrumb: [
        {
          text: 'Рекомендации',
          active: true,
        },
      ],
    },
  },
]
